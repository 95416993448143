import { TFunction } from 'i18next'
import styled from 'styled-components'

import Button from '../../../../../components/Button'
import Heading from '../../../../../components/Heading'
import Modal from '../../../../../components/Modal'
import Paragraph from '../../../../../components/Paragraph/Paragraph.component'

type Props = {
  onClose: () => void
  t: TFunction
}

const StyledFormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

const SupportTicketDialogForm: React.FC<Props> = props => {
  const { onClose, t } = props

  return (
    <Modal isOpen>
      <Heading color={'bf-blue'} level={'h5'}>
        {t('weak-identification.join-to-organization-support-ticket-title')}
      </Heading>
      <Paragraph>
        {t('weak-identification.join-to-organization-support-ticket-description')}
      </Paragraph>
      <StyledFormFooter>
        <Button
          variant={'primary'}
          onClick={() => onClose()}
          label={t('weak-identification.join-to-organization-support-ticket-continue')}
        />
      </StyledFormFooter>
    </Modal>
  )
}

export default SupportTicketDialogForm
