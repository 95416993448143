export enum PromptValues {
  Login = 'login',
  WeakIdentificationProcess = 'weakIdentificationProcess',
  None = 'none',
}

export enum SearchQueryValues {
  Code = 'code',
  State = 'state',
  Error = 'error',
  Prompt = 'prompt',
}

export enum RedirectValues {
  Prompt = 'prompt',
  SelectedOrg = 'selectedOrg',
  AcrValues = 'acr_values',
  RedirectUrl = 'redirectUrl',
}
