import { SESSION_KEY_REDIRECT, SESSION_KEY_SERVICE } from '../../../constants'
import { IServiceEntity } from '../../../shared/interfaces'
import { SessionStorageKeys } from './WeakIdentification.schema'
import { WeakIdentificationUrls } from './WeakIdentification.urls'

/**
 * Sets the redirect url for the weak identication.
 * @param mode
 */
export function setWeakIdentificationRedirectUrl(url?: string): void {
  console.log(url, 'REDIRECT_URLLLL')
  sessionStorage.setItem(SESSION_KEY_REDIRECT, url || WeakIdentificationUrls.miscInformationStep)
}

/**
 * Gets the redirect url for the weak identication.
 * @returns {string | null}
 */
export function getWeakIdentificationRedirectUrl(): string | null {
  return sessionStorage.getItem(SessionStorageKeys.redirectUrl)
}

/**
 * Sets the selected business id.
 * @param businessId
 */
export function setSelectedBusinessId(businessId: string) {
  sessionStorage.setItem(SessionStorageKeys.selectedBusinessId, businessId)
}

/**
 * Deletes the selected business id.
 */
export function deleteSelectedBusinessId() {
  sessionStorage.removeItem(SessionStorageKeys.selectedBusinessId)
}

/**
 * Gets the selected business id.
 * @returns {string | null}
 */
export function getSelectedBusinessId(): string | null {
  return sessionStorage.getItem(SessionStorageKeys.selectedBusinessId)
}

/**
 * Gets stored session service id
 * @returns {string | null}
 */
export function getServiceId(): string | null {
  const service = sessionStorage.getItem(SESSION_KEY_SERVICE)

  return service ? JSON.parse(service).id : null
}

/**
 * Gets service
 * @returns {IServiceEntity | null}
 */
export function getService(): IServiceEntity | null {
  try {
    const service = sessionStorage.getItem(SESSION_KEY_SERVICE)

    return service ? JSON.parse(service) : null
  } catch (error) {
    console.error('Error parsing service from session storage', error)

    return null
  }
}

/**
 * Checks if service requires organization selection
 * @returns {boolean}
 */
export function requiresOrganization(): boolean {
  try {
    const service = sessionStorage.getItem(SESSION_KEY_SERVICE)

    if (!service) {
      throw new Error('Service not found in session storage')
    }

    const serviceAsObject = JSON.parse(service) as IServiceEntity

    return Boolean(serviceAsObject.redirection?.preferences?.requireOrgSelection)
  } catch {
    console.warn("Couldn't parse a service")

    return false
  }
}

/**
 * Little helper function to make user wait for a while before continuing. This is for
 * testing purposes about ubis database sync
 * @param ms
 * @returns {Promise<boolean>}
 */
export async function wait(ms: number): Promise<boolean> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true)
    }, ms)
  })
}
