import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import Button from '../../../../../components/Button'
import CenteredLoadingIndicator from '../../../../../components/CenteredLoadingIndicator/CenteredLoadingIndicator.component'
import Heading from '../../../../../components/Heading'
import Link from '../../../../../components/Link'
import Paragraph from '../../../../../components/Paragraph/Paragraph.component'
import ReactFormContainer from '../../../../../components/ReactFormWrapper/ReactFormWrapper.component'
import Spacer from '../../../../../components/Spacer'
import { BASE_URL } from '../../../../../constants'
import { setShowSnackbar } from '../../../../../redux/slices/snackbarSlice'
import { setSpinner } from '../../../../../redux/slices/spinnerSlice'
import { ACRValues } from '../../../../../routes/Login/Authenticate/Authenticate.schema'
import { PromptValues, RedirectValues } from '../../../../../routes/Sso/Sso.schema'
import { Spacing } from '../../../../../shared/enums'
import {
  WeakIdentificationRequestKeys,
  handleSendSupportTicket,
  handleWeakIdentificationCheckOrganization,
  handleWeakIdentificationJoinOrganization,
  postAuthorize,
  syncSession,
} from '../../WeakIdentification.requests'
import {
  FormInputElementDefaultProps,
  SupportTicketRequestType,
  WeakIdentificationJoinOrganizationStepType,
  WeakIdentificationValidationJoinToOrganizationStep,
} from '../../WeakIdentification.schema'
import { WeakIdentificationUrls } from '../../WeakIdentification.urls'
import {
  getService,
  setSelectedBusinessId,
  setWeakIdentificationRedirectUrl,
} from '../../WeakIdentification.utils'
import SupportTicketDialogForm from './SupportTicketDialog.component'

const JoinOrganizationFormFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: ${Spacing.ExtraLarge};
`

/**
 * JoinToOrganization step form.
 * @param props
 * @returns {FunctionComponent<FormInputElementDefaultProps>}
 */
const JoinToOrganizationForm: FunctionComponent<FormInputElementDefaultProps> = props => {
  const { t, dispatch, service, hasRegistered } = props

  // We will use react-form-hooks from now on. This is the first step to convert the form to react-form-hooks.
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<WeakIdentificationJoinOrganizationStepType>({
    defaultValues: {
      businessId: '',
      service,
    },
    resolver: zodResolver(WeakIdentificationValidationJoinToOrganizationStep),
  })
  const { i18n } = useTranslation()

  const parsedService = getService()
  const navigate = useNavigate()
  const postAuthorizeIFrame = useRef<HTMLIFrameElement>(null)
  const [redirecting, setRedirecting] = useState<boolean>(false)
  const [preDirecting, setPredirecting] = useState<boolean>(false)
  const [supportTicketOpen, setSupportTicketOpen] = useState<boolean>(false)
  const submitRequest = useMutation({
    mutationKey: [WeakIdentificationRequestKeys.joinToOrganization],
    mutationFn: handleWeakIdentificationJoinOrganization,
  })
  const checkBusinessId = useMutation({
    mutationKey: [WeakIdentificationRequestKeys.checkOrganization],
    mutationFn: handleWeakIdentificationCheckOrganization,
  })
  const sendSupportTicket = useMutation({
    mutationKey: [WeakIdentificationRequestKeys.supportTicket],
    mutationFn: handleSendSupportTicket,
  })
  const syncSessionRequest = useQuery({
    queryKey: [WeakIdentificationRequestKeys.syncSession],
    queryFn: syncSession,
    enabled: false,
  })
  const postAuthorizeRequest = useQuery({
    queryKey: [WeakIdentificationRequestKeys.postAuthorize],
    queryFn: postAuthorize,
    enabled: false,
  })

  useEffect(() => {
    dispatch(
      setSpinner({
        visible:
          checkBusinessId.isLoading || submitRequest.isLoading || sendSupportTicket.isLoading,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBusinessId.isLoading, submitRequest.isLoading, sendSupportTicket.isLoading])

  /**
   * Creates a redirect url for the user to be redirected to the service.
   * @param requireBusinessIdCheck
   * @returns {Promise<string | undefined>}
   */
  const createRedirectUrl = async (requireBusinessIdCheck = true): Promise<string | null> => {
    if (!parsedService) {
      return null
    }

    try {
      setPredirecting(true)
      const storedServiceRedirectUrl = parsedService?.redirection?.url
      let selectedBusinessId: null | string = null

      if (requireBusinessIdCheck) {
        const businessId = watch('businessId')
        const businessIdCheck = await checkBusinessId.mutateAsync({ businessId, service })

        selectedBusinessId = businessIdCheck?.hasErrors ? null : businessId
      }

      syncSessionRequest.refetch()
      await postAuthorizeRequest.refetch()

      if (!storedServiceRedirectUrl) {
        console.warn('Service redirect url is missing')

        return null
      }

      const redirectUrl = new URL(storedServiceRedirectUrl)
      // If user is in unregistered mode in UBI we need to prompt the user to login
      redirectUrl.searchParams.append(
        RedirectValues.Prompt,
        hasRegistered ? PromptValues.None : PromptValues.Login
      )

      if (selectedBusinessId && requireBusinessIdCheck) {
        redirectUrl.searchParams.append(RedirectValues.SelectedOrg, selectedBusinessId)
      }

      redirectUrl.searchParams.append(RedirectValues.AcrValues, ACRValues.EmailConfirmation)
      setWeakIdentificationRedirectUrl(redirectUrl.toString())

      return redirectUrl.toString()
    } catch (error) {
      console.warn(`Error while trying to redirect: ${error}`)
      setRedirecting(false)

      return null
    }
  }

  /**
   * If service doesn't require organization selection we can redirect the user straight to the service.
   * @returns
   */
  const handleStraightRedirect = async () => {
    const redirectUrl = await createRedirectUrl(false)

    if (!redirectUrl) {
      console.warn('No redirect url found')

      return
    }

    setWeakIdentificationRedirectUrl(redirectUrl)
    setRedirecting(true)
  }

  useEffect(() => {
    if (!parsedService) {
      return
    }

    try {
      if (parsedService?.redirection?.preferences.requireOrgSelection) {
        return
      }

      handleStraightRedirect()
    } catch (error) {
      console.warn(`Error while trying to redirect: ${error}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedService])

  /**
   * This function handles redirecting the user to the service and redirects the user to the service.
   * @returns {Promise<void>}
   */
  const handleRedirect = async () => {
    if (!parsedService) {
      console.warn('Service is missing')

      throw new Error('Service is missing')
    }

    try {
      const redirectUrl = await createRedirectUrl()

      if (!redirectUrl) {
        console.warn('Redirect url is missing')

        return
      }

      setWeakIdentificationRedirectUrl(redirectUrl)

      const validationEndpoint = new URL(`/api/services/${parsedService?.id}/redirect`, BASE_URL)

      validationEndpoint.searchParams.append(RedirectValues.RedirectUrl, redirectUrl.toString())
      const res = await fetch(validationEndpoint.toString())
      const data = await res.json()
      setWeakIdentificationRedirectUrl(data.redirectUrl)
      setRedirecting(true)
    } catch (error) {
      console.warn(`Error while trying to redirect: ${error}`)
    }
  }

  /**
   * This function sends a body with businessId to the API and if it's successful it will navigate the user to the next step.
   * @param data
   */
  const handleJoinToOrganization = (data: WeakIdentificationJoinOrganizationStepType) => {
    console.log('join')
    submitRequest.mutate(data, {
      onSuccess: response => {
        console.log(response, 'response')

        if (typeof response === 'string') {
          dispatch(
            setShowSnackbar({
              error: false,
              showSnackbar: true,
              message: t(response),
            })
          )
          dispatch(setSpinner({ visible: false }))
          // We set the selected business-id to session storage to use it on the next step
          setSelectedBusinessId(data.businessId)
          console.log('handle redirect')

          return handleRedirect()
        }

        return dispatch(setShowSnackbar({ error: true, showSnackbar: true, message: t(response) }))
      },
      onError: error => {
        console.warn(error)
        dispatch(
          setShowSnackbar({
            error: true,
            showSnackbar: true,
            message: t(error as string),
          })
        )
      },
    })
  }

  /**
   * This function checks on the background does the user have privileges to join the organization via e-mail and business id.
   * @param businessId
   * @returns
   */
  const handleBusinessIdCheck = (businessId: string) => {
    clearErrors('businessId')
    clearErrors('userHasNoPrivileges')
    checkBusinessId.reset()

    if (businessId.trim().length === 0) {
      return
    }

    const body: WeakIdentificationJoinOrganizationStepType = {
      businessId,
      service: '',
    }
    // Validates the business id check
    const isValidBusinessId = WeakIdentificationValidationJoinToOrganizationStep.safeParse(body)

    // If the business id is not valid we set the errors
    if (!isValidBusinessId.success) {
      setError('businessId', {
        message: t('weak-identification.join-to-organization-invalid-business-id') as string,
      })

      return
    }

    // We call the API to check if the business id is valid or if the user has privileges to join the organization
    return checkBusinessId.mutate(body, {
      onSuccess: data => {
        if (data.hasErrors) {
          if (!data.existsInGlobalCRM) {
            return setError('businessId', {
              message: t('weak-identification.join-to-organization-not-in-crm') as string,
            })
          }

          if (!data.isInCrm) {
            setError('businessId', {
              message: t(
                'weak-identification.join-to-organization-no-privileges-to-join'
              ) as string,
            })
          }

          // Sometimes user's session disappears.
          if (!data.usersDomain) {
            setShowSnackbar({
              error: true,
              showSnackbar: true,
              message: t('weak-identification.session-expired'),
            })
            navigate(WeakIdentificationUrls.emailStep)
          }

          return dispatch(
            setShowSnackbar({
              error: true,
              showSnackbar: true,
              message: t('weak-identification.join-to-organization-no-privileges-to-join'),
            })
          )
        }

        // this is for syncing purposes in here
        postAuthorizeRequest.refetch()

        return dispatch(
          setShowSnackbar({
            error: false,
            showSnackbar: true,
            message: t('weak-identification.join-to-organization-organization-privileges-to-join', {
              organizationName: data.organizationName,
            }),
          })
        )
      },
      onError: error => {
        console.warn(error)
        setError('businessId', {
          message: t('weak-identification.join-to-organization-check-organization-error') as string,
        })

        return dispatch(
          setShowSnackbar({
            error: true,
            showSnackbar: true,
            message: t('weak-identification.join-to-organization-check-organization-error'),
          })
        )
      },
    })
  }

  /**
   * This function handles sending of support ticket and redirects user
   * when server response is 200. We don't save business id to session storage
   * when this button is used.
   */
  const handleSupportTicket = () => {
    const payload: SupportTicketRequestType = {
      businessId: watch('businessId'),
      serviceId: service,
      lang: i18n.language,
    }
    sendSupportTicket.mutate(payload, {
      onSuccess: () => {
        dispatch(setSpinner({ visible: false }))
        setSupportTicketOpen(true)
      },
      onError: () => {
        dispatch(setSpinner({ visible: false }))
        dispatch(
          setShowSnackbar({
            error: true,
            showSnackbar: true,
            message: t('weak-identification.join-to-organization-support-ticket-error'),
          })
        )
      },
    })
  }

  /**
   * At this point due I have some errors in my local env we handle this this way.
   * @returns
   */
  const handleSupportTicketContinue = async () => {
    if (!sendSupportTicket.data) {
      return
    }

    const redirectUrl = await createRedirectUrl(false)

    if (!redirectUrl) {
      console.warn('Redirect url is missing')

      return
    }

    setSupportTicketOpen(false)
    setWeakIdentificationRedirectUrl(redirectUrl)
    setRedirecting(true)
  }

  if (!redirecting && preDirecting) {
    return <CenteredLoadingIndicator />
  }

  if (redirecting) {
    return (
      <>
        <CenteredLoadingIndicator />
        {postAuthorizeRequest.data ? (
          <iframe
            ref={postAuthorizeIFrame}
            sandbox={
              'allow-forms allow-same-origin allow-top-navigation allow-scripts allow-popups allow-popups-to-escape-sandbox'
            }
            referrerPolicy="origin"
            title={'Post authorization'}
            src={postAuthorizeRequest.data}
            style={{ height: 1, width: 1, position: 'absolute' }}
          />
        ) : null}
      </>
    )
  }

  return (
    <ReactFormContainer
      style={{ marginTop: '2rem', marginBottom: '2rem' }}
      onSubmit={handleSubmit(handleJoinToOrganization)}
    >
      <Heading color={'bf-blue'} level={'h4'}>
        {t('weak-identification.join-to-organization-form-title')}
      </Heading>
      <Paragraph>
        {t('weak-identification.join-to-organization-form-description-row-one')}
        <Link
          style={{ marginLeft: 4 }}
          target="_blank"
          href={t('weak-identification.join-to-organization-check-business-id-link') as string}
        >
          {t('weak-identification.join-to-organization-check-business-id-helper-trigger')}
        </Link>
      </Paragraph>
      <Spacer size={'large'} />
      <label>{t('weak-identification.join-to-organization-business-id-label')}</label>
      <input
        type={'text'}
        {...register('businessId')}
        onBlur={e => handleBusinessIdCheck(e.target.value)}
        style={{ borderColor: checkBusinessId.data?.hasErrors ? 'red' : undefined }}
      />
      {errors.businessId ? (
        <span className="error-message">{errors.businessId.message}</span>
      ) : null}

      {errors.businessId ? (
        <div>
          {checkBusinessId?.data?.existsInGlobalCRM ? (
            <>
              <Spacer size={'large'} />
              <Button
                label={t('weak-identification.join-to-organization-give-more-information-button')}
                variant={'primary'}
                onClick={() => handleSupportTicket()}
              />
            </>
          ) : null}
        </div>
      ) : null}
      {!checkBusinessId.data?.hasErrors && checkBusinessId.data ? (
        <span className="success-message">
          {t('weak-identification.join-to-organization-organization-privileges-to-join', {
            organizationName: checkBusinessId.data?.organizationName,
          })}
        </span>
      ) : null}
      <JoinOrganizationFormFooter>
        <Button
          disabled={
            checkBusinessId.isLoading ||
            submitRequest.isLoading ||
            checkBusinessId.isError ||
            checkBusinessId?.data?.hasErrors
          }
          label={t('weak-identification.join-to-organization-form-submit')}
          variant={'primary'}
          type={'submit'}
        />
      </JoinOrganizationFormFooter>
      {supportTicketOpen ? (
        <SupportTicketDialogForm onClose={() => handleSupportTicketContinue()} t={t} />
      ) : null}
    </ReactFormContainer>
  )
}

export { JoinToOrganizationForm }
