/**
 * All the validations that are correspondent for WeakIdentification workflow are
 * stored in here. Here we also export typings by using zod infer to be used on the
 * frontend side. In the future it would be a good idea to use monorepo to store these
 * kind of validations to be used everywhere in this application.
 */
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { TFunction } from 'i18next'
import { FunctionComponent } from 'react'
import { z } from 'zod'

import { isValidName, validateFinnishBusinessID } from '../../../utils/validation-utils'

// #region Enums
export enum SessionStorageKeys {
  email = 'bf_session_email',
  redirectUrl = 'bf_session_redirect_url',
  selectedBusinessId = 'bf_session_selected_business_id',
}

export enum searchQueryParameters {
  view = 'view',
}

export enum FormInput {
  email = 'email',
  miscInformation = 'miscInformation',
  joinToOrganization = 'joinToOrganization',
}
// #endregion

//#region Zod validations
// This validation is for searchQuery of weakIdentification wrapper
export const WeakIdentificationSearchQuery = z.object({
  view: z.nativeEnum(FormInput),
})

export const WeakIdentificationValidationMiscInformationStep = z.object({
  firstName: z
    .string()
    .min(2)
    .max(128)
    .refine(name => {
      return isValidName(name)
    }),
  lastName: z
    .string()
    .min(2)
    .refine(name => {
      return isValidName(name)
    }),
  // Checkboxes
  termsAndConditions: z.boolean().refine(boolean => {
    return boolean
  }),
  email: z.string().optional(),
})

export const WeakIdentificationValidationJoinToOrganizationStep = z.object({
  businessId: z.string().refine(businessId => {
    return validateFinnishBusinessID(businessId)
  }),
  service: z.string().optional(),
  userHasNoPrivileges: z.boolean().optional(),
})

export const WeakIdentificationMiscInformationRequestResponse = z.object({
  bfId: z.string(),
  crmId: z.string(),
  email: z.string(),
})

export const PrivilegeCheckResponse = z.object({
  isInCrm: z.boolean(),
  validEmailDomain: z.boolean(),
  hasErrors: z.boolean(),
  role: z.enum(['L1', 'L2']),
  trustedDomains: z.array(z.string()),
  usersDomain: z.string().nullable(),
  organizationName: z.string().nullable(),
  existsInGlobalCRM: z.boolean(),
})

export const WeakIdenticationVerificationResponse = z.object({
  hasErrors: z.boolean(),
})

export const SupportTicketRequest = z.object({
  businessId: z.string(),
  serviceId: z.string(),
  lang: z.string(),
})

export const OnBoardingStatus = z.object({
  hasFilledAdditionalInformation: z.boolean(),
  hasSSN: z.boolean(),
  hasOrganization: z.boolean(),
  hasEmail: z.boolean(),
  hasRegistered: z.boolean(),
})

// #endregion Zod validations

// #region Misc types

// Form configuration works like we have an query parameter called "view" which has a value of formInput.
// By using FormInput we're gonna render a FormInputs element
export type FormInputElementDefaultProps = {
  onSuccess: (currentFormInputKey: FormInput) => void
  t: TFunction
  testMode?: boolean
  dispatch: Dispatch<AnyAction>
  service: string
  hasRegistered: boolean
}

export type FormInputConfiguration = {
  element: FunctionComponent<FormInputElementDefaultProps>
  indexNumber: number
  translationKey: string
}

export type FormConfiguration = Record<FormInput, FormInputConfiguration>

// #endregion

// #region for Exporting types
export type WeakIdentificationSearchQueryType = z.infer<typeof WeakIdentificationSearchQuery>
export type WeakIdentificationValidationMiscInformationStepType = z.infer<
  typeof WeakIdentificationValidationMiscInformationStep
>
export type WeakIdentificationJoinOrganizationStepType = z.infer<
  typeof WeakIdentificationValidationJoinToOrganizationStep
>
export type WeakIdentificationMiscInformationRequestResponseType = z.infer<
  typeof WeakIdentificationMiscInformationRequestResponse
>
export type WeakIdentificationPrivilegeCheckResponseType = z.infer<typeof PrivilegeCheckResponse>
export type WeakIdenticationVerificationResponseType = z.infer<
  typeof WeakIdenticationVerificationResponse
>
export type SupportTicketRequestType = z.infer<typeof SupportTicketRequest>
export type OnBoardingStatusType = z.infer<typeof OnBoardingStatus>
// #endregion
