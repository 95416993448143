import { SESSION_KEY_SERVICE } from '../constants'

/**
 * This function determines with boolean value should organization select
 * be added to the login flow. HOX! This function works only when used
 * in the current workflow where serviceId is passed into a query parameter.
 * @returns {boolean} true if organization select is required
 */
export function serviceRequiresOrganizationSelect(): boolean {
  const service = sessionStorage.getItem(SESSION_KEY_SERVICE)

  if (!service) {
    return false
  }

  try {
    const parsedService = JSON.parse(service)

    return parsedService.redirection?.preferences?.requireOrgSelection
  } catch {
    console.warn('Error while trying to parse service')

    return false
  }
}
