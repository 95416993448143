import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'

import tokenApi from '../../api/tokens'
import CenteredLoadingIndicator from '../../components/CenteredLoadingIndicator/CenteredLoadingIndicator.component'
import { QUERY_KEY_AUTH_CALLBACK, SESSION_KEY_REDIRECT } from '../../constants'
import { insideIframe } from '../../utils/window-utils'

const FALLBACK_REDIRECT = '/portal'

const reAuthenticate = async () => {
  const response = await fetch('/api/tokens/login?prompt=login', {
    credentials: 'include',
  })
  const data = await response.json()

  window.location.assign(data.authRedirUrl)
}

const Sso: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const state = searchParams.get('state')
  const error = searchParams.get('error')
  const prompt = searchParams.get('prompt')

  //When authentication is canceled, inform user and redirect him to login page
  if (error) {
    return <Navigate to="/canceledlogin" />
  }

  if (!code || !state) {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (prompt === 'login') {
      reAuthenticate()

      return null
    }

    return <Navigate to="/canceledlogin" />
  }

  const redirectUrl = sessionStorage.getItem(SESSION_KEY_REDIRECT) || FALLBACK_REDIRECT

  const { status } = useQuery(
    [QUERY_KEY_AUTH_CALLBACK],
    () => tokenApi.processAuthResponse(code, state),
    {}
  )

  useEffect(() => {
    if (status === 'success') {
      if (insideIframe()) {
        window.top?.location.assign(redirectUrl)
      } else {
        window.location.assign(redirectUrl)
      }

      sessionStorage.removeItem(SESSION_KEY_REDIRECT)
    }
  }, [redirectUrl, status])

  return <CenteredLoadingIndicator />
}

export default Sso
